<template>
    <div class="pagebox">

        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 院校详情 -->
        <div class="college_detail_head" :style="{'background':'url(https://jzy-dev.obs.cn-north-4.myhuaweicloud.com/UserFile/School/1-1.jpg) no-repeat top center / cover'}">
            <div class="head_back">
                <div class="head_auto">
                    <div class="head_box">
                        <div class="head_top">
                            <div class="logo_box">
                                <div class="logo">
                                    <div class="img" :style="{'background':'url(https://jzy-dev.obs.cn-north-4.myhuaweicloud.com/UserFile/logo/3.jpg) no-repeat center center / cover'}"></div>
                                </div>
                            </div>
                            <div class="txt_box">
                                <div class="cname">
                                    <span class="name">清华大学</span>
                                    <span class="type">北京/北京市</span>
                                    <span class="type">综合/公办/教育部</span>
                                </div>
                                <div class="dress">
                                    <span><i class="el-icon-location-outline"></i>北京市海淀区清华园1号</span>
                                    <span><i class="el-icon-phone-outline"></i>010-62770334, 62782051</span>
                                </div>
                                <div class="tip_box">
                                    <span>985</span>
                                    <span>211</span>
                                    <span>双一流</span>
                                    <span>研究生院</span>
                                    <span>国重点</span>
                                    <span>保研</span>
                                    <span>博士授权学科</span>
                                    <span>九校联盟</span>
                                    <span>中国高校行星科学联盟</span>
                                    <span>电气二龙四虎</span>
                                    <span>建筑老八校</span>
                                    <span>卓越医生</span>
                                    <span>卓越法律</span>
                                </div>
                            </div>
                            <div class="btn_box">
                                <div class="btn">进入官网</div>
                                <div class="btn" @click.prevent="OpenReport()">就业质量报告预览</div>
                                <div class="btn">VR 校园</div>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 左侧菜单 -->
        <div class="college_left_nav" id="college_left_nav" :class="left_nav_class">
            <div class="open" @click.prevent="LeftNavDownUp">
                <span v-if="LeftNavStatus"><i class="el-icon-d-arrow-right up"></i>收起导航</span>
                <span v-else><i class="el-icon-d-arrow-right down"></i>展开导航</span>
            </div>
            <el-scrollbar wrap-class="scrollbar-wrapper" class="left_nav_box" id="left_nav_box">
                <div class="nav" :class="(TabActiveId=='Tab1'?'on':'')" @click.prevent="LeftNavBtn('Tab1')">院校介绍</div>
                <div class="nav" :class="(TabActiveId=='Tab2'?'on':'')" @click.prevent="LeftNavBtn('Tab2')">开设专业</div>
                <div class="nav" :class="(TabActiveId=='Tab3'?'on':'')" @click.prevent="LeftNavBtn('Tab3')">学科评估</div>
                <div class="nav" :class="(TabActiveId=='Tab4'?'on':'')" @click.prevent="LeftNavBtn('Tab4')">招生章程</div>
                <div class="nav" :class="(TabActiveId=='Tab5'?'on':'')" @click.prevent="LeftNavBtn('Tab5')">选考要求</div>
                <div class="nav" :class="(TabActiveId=='Tab6'?'on':'')" @click.prevent="LeftNavBtn('Tab6')">招生计划</div>
                <div class="nav" :class="(TabActiveId=='Tab7'?'on':'')" @click.prevent="LeftNavBtn('Tab7')">录取<br/>分数线</div>
            </el-scrollbar>
        </div>
        <!-- 内容 -->
        <div class="college_detail_box" id="college_detail_box">
            <div class="college_detail_con">
                <div class="tab_box">
                    <el-tabs v-model="TabActiveId" type="card">
                        <el-tab-pane label="院校概况" name="Tab1">
                            <div class="tab_con">
                                <!--院校介绍-->
                                <div class="con_box">
                                    <div class="title"><span class="on">院校介绍</span></div>
                                    <div class="content">
                                        <div class="info">
                                            <p>北京大学创办于1898年，是戊戌变法的产物，也是中华民族救亡图存、兴学图强的结果，初名京师大学堂，是中国近现代第一所国立综合性大学，辛亥革命后，于1912年改为现名。</p>
                                            <p>在悠久的文明历程中，古代中国曾创立太学、国子学、国子监等国家最高学府，在中国和世界教育史上具有重要影响。北京大学“上承太学正统，下立大学祖庭”，既是中华文脉和教育传统的传承者，也标志着中国现代高等教育的开端。其创办之初也是国家最高教育行政机关，对建立中国现代学制作出重要历史贡献。</p>
                                            <p>作为新文化运动的中心和五四运动的策源地，作为中国最早传播马克思主义和民主科学思想的发祥地，作为中国共产党最初的重要活动基地，北京大学为民族的振兴和解放、国家的建设和发展、社会的文明和进步做出了突出贡献，在中国走向现代化的进程中起到了重要的先锋作用。爱国、进步、民主、科学的精神和勤奋、严谨、求实、创新的学风在这里生生不息、代代相传。</p>
                                            <p>1917年，著名教育家蔡元培就任北京大学校长，他“循思想自由原则，取兼容并包主义”，对北京大学进行了卓有成效的改革，促进了思想解放和学术繁荣。陈独秀、李大钊、毛泽东以及鲁迅、胡适、李四光等一批杰出人士都曾在北京大学任教或任职。</p>
                                            <p>1937年卢沟桥事变后，北京大学与清华大学、南开大学南迁长沙，共同组成国立长沙临时大学。1938年，临时大学又西迁昆明，更名为国立西南联合大学。抗日战争胜利后，北京大学于1946年10月在北平复员。</p>
                                        </div>
                                        <div class="more">
                                            <div class="back"></div>
                                            <div class="btn">
                                                <div class="b" @click.prevent="DetailInfoLayerOpen()">
                                                    查看更多内容<i class="el-icon-d-arrow-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="detail_layer_back" v-if="DetailInfoLayerShow" :class="DetailLayerClass">
                                            <div class="detail_layer_box">
                                                <div class="layer_con" :class="DetailLayerConClass">
                                                    <div class="bt"><span class="tt">院校介绍</span><i class="el-icon-close" @click.prevent="DetailInfoLayerClose()"></i></div>
                                                    <el-scrollbar wrap-class="scrollbar-wrapper" class="con">
                                                        <p>北京大学创办于1898年，是戊戌变法的产物，也是中华民族救亡图存、兴学图强的结果，初名京师大学堂，是中国近现代第一所国立综合性大学，辛亥革命后，于1912年改为现名。</p>
                                                        <p>在悠久的文明历程中，古代中国曾创立太学、国子学、国子监等国家最高学府，在中国和世界教育史上具有重要影响。北京大学“上承太学正统，下立大学祖庭”，既是中华文脉和教育传统的传承者，也标志着中国现代高等教育的开端。其创办之初也是国家最高教育行政机关，对建立中国现代学制作出重要历史贡献。</p>
                                                        <p>作为新文化运动的中心和五四运动的策源地，作为中国最早传播马克思主义和民主科学思想的发祥地，作为中国共产党最初的重要活动基地，北京大学为民族的振兴和解放、国家的建设和发展、社会的文明和进步做出了突出贡献，在中国走向现代化的进程中起到了重要的先锋作用。爱国、进步、民主、科学的精神和勤奋、严谨、求实、创新的学风在这里生生不息、代代相传。</p>
                                                        <p>1917年，著名教育家蔡元培就任北京大学校长，他“循思想自由原则，取兼容并包主义”，对北京大学进行了卓有成效的改革，促进了思想解放和学术繁荣。陈独秀、李大钊、毛泽东以及鲁迅、胡适、李四光等一批杰出人士都曾在北京大学任教或任职。</p>
                                                        <p>1937年卢沟桥事变后，北京大学与清华大学、南开大学南迁长沙，共同组成国立长沙临时大学。1938年，临时大学又西迁昆明，更名为国立西南联合大学。抗日战争胜利后，北京大学于1946年10月在北平复员。</p>
                                                        <p>中华人民共和国成立后，全国高校于1952年进行院系调整，北京大学成为一所以文理基础教学和研究为主、兼有前沿应用学科的综合性大学，为社会主义建设事业培养了大批杰出人才，在23位“两弹一星”元勋中有12位北大校友。
                                改革开放以来，北京大学进入了稳步快速发展的新时期，于1994年成为国家“211工程”首批重点建设大学。1998年5月4日，在庆祝北京大学建校一百周年大会上，党中央发出了“为了实现现代化，我国要有若干所具有世界先进水平的一流大学”的号召，之后启动了建设世界一流大学的“985工程”。在这一国家战略的支持和推动下，北京大学的发展翻开了新的一页。
                                2000年4月3日，北京大学与原北京医科大学合并，组建了新的北京大学。原北京医科大学的前身是创建于1912年10月26日的国立北京医学专门学校， 1903年京师大学堂设立的医学实业馆为这所国立西医学校的诞生奠定了基础。20世纪三、四十年代，学校一度名为北平大学医学院，并于1946年7月并入北京大学。1952年在全国高校院系调整中，北京大学医学院脱离北京大学，独立为北京医学院。1985年更名为北京医科大学，1996年成为国家首批“211工程”重点支持的医科大学。两校合并进一步拓宽了北京大学的学科结构，为促进医学与理科、工科以及人文社会科学的交叉融合，改革创新医学教育奠定了基础。
                                党的十八大以来，在以习近平同志为核心的党中央坚强领导下，北大加快推进高质量内涵式发展，在建设中国特色世界一流大学的新征程上取得新突破。2018年5月2日，习近平总书记到北大考察，并在北大师生座谈会上指出：“近年来，北大继承光荣传统，坚持社会主义办学方向，立德树人成果丰硕，双一流建设成效显著，服务经济社会发展成绩突出，学校发展思路清晰，办学实力和影响力显著增强，令人欣慰。”在习近平总书记的勉励和指引下，北京大学再接再厉、接续奋斗，坚持当好培养高素质拔尖创新人才的摇篮、创造前沿科技和先进思想文化的重要阵地、服务国家高质量发展的枢纽平台、推动高水平对外开放的桥梁纽带，在新征程上不断谱写建设中国特色世界一流大学的崭新篇章。
                                现任校党委书记郝平、校长龚旗煌。</p>
                                                    </el-scrollbar>
                                                    <div class="back">
                                                        <div class="btn" @click.prevent="DetailInfoLayerClose()">返 回</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--院校风光-->
                                <div class="con_box">
                                    <div class="title"><span class="on">院校风光</span></div>
                                    <div class="content">
                                        <div class="imgbox">
                                            <div class="img" :style="{'background':'url(https://jzy-dev.obs.cn-north-4.myhuaweicloud.com/UserFile/School/1-1.jpg) no-repeat center center / cover'}"></div>
                                            <div class="img" :style="{'background':'url(https://jzy-dev.obs.cn-north-4.myhuaweicloud.com/UserFile/School/1-2.jpg) no-repeat center center / cover'}"></div>
                                            <div class="img" :style="{'background':'url(https://img1.youzy.cn/content/media/thumbs/p00004941.jpeg) no-repeat center center / cover'}"></div>
                                            <div class="img" :style="{'background':'url(https://jzy-dev.obs.cn-north-4.myhuaweicloud.com/UserFile/School/1-3.jpg) no-repeat center center / cover'}"></div>
                                        </div>
                                    </div>
                                </div>
                                <!--院校排名、综合评级-->
                                <div class="con_box">
                                    <div class="title"><span :class="(OrderNoBox==1?'on':'no')" @click.prevent="OrderNoBtn(1)">院校排名</span><span :class="(OrderNoBox==2?'on':'no')" @click.prevent="OrderNoBtn(2)">综合评级</span></div>
                                    <div class="content">
                                        <div class="orderbox" v-if="OrderNoBox==1">
                                            <div class="type">
                                                <div class="t">ESI</div>
                                                <div class="t">软科</div>
                                                <div class="t">QS</div>
                                                <div class="t">USNews</div>
                                                <div class="t">泰晤士</div>
                                                <div class="t">校友会</div>
                                                <div class="t">武书连</div>
                                            </div>
                                            <div class="order">
                                                <div class="o">5 / 56</div>
                                                <div class="o">2</div>
                                                <div class="o">1 / 17</div>
                                                <div class="o">2 / 39</div>
                                                <div class="o">2 / 14</div>
                                                <div class="o">1</div>
                                                <div class="o">2</div>
                                            </div>
                                        </div>
                                        <div class="orderbox" v-if="OrderNoBox==2">
                                            <div class="type">
                                                <div class="t">综合实力</div>
                                                <div class="t">专业实力</div>
                                                <div class="t">城市</div>
                                                <div class="t">就业</div>
                                                <div class="t">地域</div>
                                                <div class="t">属性</div>
                                                <div class="t">总分</div>
                                            </div>
                                            <div class="order">
                                                <div class="o">29.94</div>
                                                <div class="o">16.53</div>
                                                <div class="o">10</div>
                                                <div class="o">10</div>
                                                <div class="o">10</div>
                                                <div class="o">9.5</div>
                                                <div class="o">85.97</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--实验室与研究机构、师资力量-->
                                <div class="con_box">
                                    <div class="title"><span :class="(AbilityBox==1?'on':'no')" @click.prevent="AbilityBoxBtn(1)">实验室与研究机构</span><span :class="(AbilityBox==2?'on':'no')" @click.prevent="AbilityBoxBtn(2)">师资力量</span></div>
                                    <div class="content">
                                        <div class="info nonum" v-if="AbilityBox==1">
                                            <p><b>国家工程实验室：</b>口腔数字化医疗技术和材料国家工,数字视频编解码技术国家工程实验</p>
                                            <p><b>国家重点实验室：</b>蛋白质工程和植物基因工程国家重,分子动态与稳态结构国家重点实验,核物理与核技术国家重点实验室,环境模拟与污染控制国家重点实验,区域光纤通信网与新型光通信系统,人工微结构和介观物理国家重点实,生物膜与膜生物工程国家重点实验,天然药物及仿生药物国家重点实验,湍流与复杂系统国家重点实验室,微米/纳米加工技术国家级重点实,稀土材料化学及应用国家重点实验</p>
                                            <p><b>国家工程研究中心：</b>电子出版新技术国家工程研究中心,软件工程国家工程研究中心</p>
                                            <p><b>教育部重点实验室：</b>地表过程分析与模拟,恶性肿瘤发病机制及应用研究,分子心血管学,辅助生殖,高分子化学与物理,高可信软件技术,机器感知与智能,计算语言学,慢性肾脏病防治,纳米器件物理与化学,神经科学,生物有机分子工程,视觉损伤与修复,数量经济与数理金融,数学及其应用,水沙科学,细胞增殖与分化,造山带与地壳演化</p>
                                            <p><b>国家实验室：</b>北京分子科学国家实验室</p>
                                            <p><b>教育部人文社会科学重点研究基地：</b>北京大学教育经济研究所,邓小平理论研究中心,东方文学研究中心,汉语语言学研究中心,美学与美育研究中心,外国哲学研究所,宪法与行政法研究中心,政治发展与政府管理研究所,中国古代史研究中心,中国古文献研究中心,中国经济研究中心,中国考古学研究中心,中国社会与发展研究中心</p>
                                        </div>
                                        <div class="info nonum" v-if="AbilityBox==2">
                                            <p>北京大学现拥有1541名正高级教职人员（其中博士生导师1117名），其中中国科学院院士49名、中国工程院院士8名、第三世界科学院院士9名、“973项目”首席科学家14人、长江特聘教授74人。北京大学的中科院院士、“973项目”首席科学家、教授、博士生导师、长江学者以及国家重点学科、重点实验室的数目，均居全国高等院校之首。</p>
                                        </div>
                                    </div>
                                </div>
                                <!--男女比例和深造情况-->
                                <div class="con_box">
                                    <div class="title"><span class="on">男女比例和深造情况</span></div>
                                    <div class="content">
                                        <div class="proportionbox">
                                            <div class="pbox">
                                                <div class="tit">男女比例</div>
                                                <div class="num">
                                                    <span class="item"><span>男生：</span><span class="n1"><span class="n2" :style="{'width':'57%'}"></span></span><span style="margin-left:10px;">57%</span><div class="clear"></div></span>
                                                    <span class="item"><span>女生：</span><span class="n1"><span class="n2" :style="{'width':'43%'}"></span></span><span style="margin-left:10px;">43%</span></span>
                                                </div>
                                            </div>
                                            <div class="pbox">
                                                <div class="tit">读研比例</div>
                                                <div class="num" style="margin-top:44px;">
                                                    <span class="item"><span class="n1"><span class="n2" :style="{'width':'59.81%'}"></span></span><span style="margin-left:10px;">59.81%</span></span>
                                                </div>
                                            </div>
                                            <div class="pbox">
                                                <div class="tit">出国留学比例</div>
                                                <div class="num" style="margin-top:44px;">
                                                    <span class="item"><span class="n1"><span class="n2" :style="{'width':'30.85%'}"></span></span><span style="margin-left:10px;">30.85%</span></span>
                                                </div>
                                            </div>
                                            <div class="clear"></div>
                                        </div>
                                    </div>
                                </div>
                                <!--历史沿革-->
                                <div class="con_box">
                                    <div class="title"><span class="on">历史沿革</span></div>
                                    <div class="content">
                                        <div class="timeline">
                                            <div class="lbox" style="max-height:300px;">
                                                <div class="line">
                                                    <div class="ra"></div>
                                                    <div class="time">1898年</div>
                                                    <div class="text">创办时间</div>
                                                </div>
                                                <div class="line">
                                                    <div class="ra"></div>
                                                    <div class="time">1898年6月</div>
                                                    <div class="text">光绪帝推行“戊戌变法”，颁布《明定国是诏》，诏书中强调“京师大学堂为各行省之倡，尤应首先举办”。</div>
                                                </div>
                                                <div class="line">
                                                    <div class="ra"></div>
                                                    <div class="time">1898年7月3日</div>
                                                    <div class="text">光绪帝批准了由梁启超代为起草的《奏拟京师大学堂章程》，正式创办京师大学堂，并任命吏部尚书、协办大学士孙家鼐为首任管理大学堂事务大臣（管学大臣），许景澄任中学总教习，美国传教士丁韪良任西学总教习。</div>
                                                </div>
                                                <div class="line">
                                                    <div class="ra"></div>
                                                    <div class="time">1900年8月3日</div>
                                                    <div class="text">义和团运动爆发，八国联军打入北京，京师大学堂遭到破坏，清政府下令停办京师大学堂。</div>
                                                </div>
                                            </div>
                                            <div class="detail_layer_back" v-if="DetailTimeLayerShow" :class="DetailLayerClass">
                                                <div class="detail_layer_box">
                                                    <div class="layer_con" :class="DetailLayerConClass">
                                                        <div class="bt"><span class="tt">历史沿革</span><i class="el-icon-close" @click.prevent="DetailTimeLayerClose()"></i></div>
                                                        <el-scrollbar wrap-class="scrollbar-wrapper" style="height: calc(100% - 140px);">
                                                            <div class="lbox">
                                                                <div class="line">
                                                                    <div class="ra"></div>
                                                                    <div class="time">1898年</div>
                                                                    <div class="text">创办时间</div>
                                                                </div>
                                                                <div class="line">
                                                                    <div class="ra"></div>
                                                                    <div class="time">1898年6月</div>
                                                                    <div class="text">光绪帝推行“戊戌变法”，颁布《明定国是诏》，诏书中强调“京师大学堂为各行省之倡，尤应首先举办”。</div>
                                                                </div>
                                                                <div class="line">
                                                                    <div class="ra"></div>
                                                                    <div class="time">1898年7月3日</div>
                                                                    <div class="text">光绪帝批准了由梁启超代为起草的《奏拟京师大学堂章程》，正式创办京师大学堂，并任命吏部尚书、协办大学士孙家鼐为首任管理大学堂事务大臣（管学大臣），许景澄任中学总教习，美国传教士丁韪良任西学总教习。</div>
                                                                </div>
                                                                <div class="line">
                                                                    <div class="ra"></div>
                                                                    <div class="time">1900年8月3日</div>
                                                                    <div class="text">义和团运动爆发，八国联军打入北京，京师大学堂遭到破坏，清政府下令停办京师大学堂。</div>
                                                                </div>
                                                                <div class="line">
                                                                    <div class="ra"></div>
                                                                    <div class="time">1902年12月17日</div>
                                                                    <div class="text">京师大学堂恢复办学。清政府委任吏部尚书张百熙为管学大臣，责成经理京师大学堂，吴汝纶和辜鸿铭任正副总教习，严复和林纾分任大学堂译书局总办和副总办。创办于1862年洋务运动期间的京师同文馆也并入大学堂。根据学制，不同程度的毕业生分别授给贡生、举人、进士头衔。</div>
                                                                </div>
                                                                <div class="line">
                                                                    <div class="ra"></div>
                                                                    <div class="time">1903年</div>
                                                                    <div class="text">增设进士馆、 译学馆及医学实业馆</div>
                                                                </div>
                                                                <div class="line">
                                                                    <div class="ra"></div>
                                                                    <div class="time">1904年</div>
                                                                    <div class="text">京师大学堂管学大臣改为总理学务大臣，负责统辖全国学务，并另设京师大学堂总监督，专管京师大学堂事宜。京师大学堂统辖全国教育的职能因此分离，成为单纯的高等学校。同年，进士馆开学，招收了中国历史上的最后两届科举进士，即癸卯科（1903年）进士80余名和甲辰科（1904年）进士30余名入学。并选派首批47名学生出国留学，开中国高校派遣留学生之先河。</div>
                                                                </div>
                                                                <div class="line">
                                                                    <div class="ra"></div>
                                                                    <div class="time">1910年</div>
                                                                    <div class="text">京师大学堂开办分科大学，开办经科、文科、法政科、商科、格致科（理科）、工科、农科共七科，设十三学门，分别是经科的诗经、周礼、春秋左传，文科的中国文学、中国史学，法政科的政治、法律，商科的银行保险，格致科的地质、化学，工科的土木、矿冶，农科的农学，中国近代的第一所综合性大学初具规模。</div>
                                                                </div>
                                                            </div>
                                                        </el-scrollbar>
                                                        <div class="back">
                                                            <div class="btn" @click.prevent="DetailTimeLayerClose()">返 回</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="more">
                                                <div class="back"></div>
                                                <div class="btn">
                                                    <div class="b" @click.prevent="DetailTimeLayerOpen()">
                                                        查看更多内容<i class="el-icon-d-arrow-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clear" style="height:30px;"></div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="开设专业" name="Tab2">
                            <div class="tab_con">
                                <div class="clear"></div>
                                <table class="subjecthead" cellspacing="0">
                                    <tr>
                                        <td>类别</td>
                                        <td>数量</td>
                                        <td>专业</td>
                                    </tr>
                                </table>
                                <table class="subjecttable" cellspacing="0">
                                    <tr>
                                        <td>优势专业</td>
                                        <td>30</td>
                                        <td>
                                            <span class="zy">哲学</span>
                                            <span class="zy">艺术学理论</span>
                                            <span class="zy">政治学</span>
                                            <span class="zy">力学</span>
                                            <span class="zy">物理学</span>
                                            <span class="zy">化学</span>
                                            <span class="zy">世界史</span>
                                            <span class="zy">外国语言文学</span>
                                            <span class="zy">生物学</span>
                                            <span class="zy">计算机科学与技术</span>
                                            <span class="zy">统计学</span>
                                            <span class="zy">应用经济学</span>
                                            <span class="zy">地理学</span>
                                            <span class="zy">大气科学</span>
                                            <span class="zy">社会学</span>
                                            <span class="zy">中国语言文学</span>
                                            <span class="zy">心理学</span>
                                            <span class="zy">基础医学</span>
                                            <span class="zy">口腔医学</span>
                                            <span class="zy">考古学</span>
                                            <span class="zy">数学</span>
                                            <span class="zy">计算机科学与技术</span>
                                            <span class="zy">统计学</span>
                                            <span class="zy">应用经济学</span>
                                            <span class="zy">地理学</span>
                                            <span class="zy">大气科学</span>
                                            <span class="zy">社会学</span>
                                            <span class="zy">中国语言文学</span>
                                            <span class="zy">心理学</span>
                                            <span class="zy">基础医学</span>
                                            <span class="zy">口腔医学</span>
                                            <span class="zy">考古学</span>
                                            <span class="zy">数学</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>特色专业</td>
                                        <td>10</td>
                                        <td>
                                            <span class="zy">基础医学</span>
                                            <span class="zy">口腔医学</span>
                                            <span class="zy">考古学</span>
                                            <span class="zy">数学</span>
                                            <span class="zy">社会学</span>
                                            <span class="zy">中国语言文学</span>
                                            <span class="zy">心理学</span>
                                            <span class="zy">应用经济学</span>
                                            <span class="zy">地理学</span>
                                            <span class="zy">大气科学</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>哲学类</td>
                                        <td>2</td>
                                        <td>
                                            <span class="zy">哲学</span>
                                            <span class="zy">宗教学</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>经济学类</td>
                                        <td>3</td>
                                        <td>
                                            <span class="zy">经济学</span>
                                            <span class="zy">经济统计学</span>
                                            <span class="zy">资源与环境经济学</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>财政学类</td>
                                        <td>1</td>
                                        <td>
                                            <span class="zy">财政学</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>金融学类</td>
                                        <td>3</td>
                                        <td>
                                            <span class="zy">金融学</span>
                                            <span class="zy">保险学</span>
                                            <span class="zy">金融数学</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>经济与贸易类</td>
                                        <td>1</td>
                                        <td>
                                            <span class="zy">经济与贸易类</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>马克思主义理论类</td>
                                        <td>1</td>
                                        <td>
                                            <span class="zy">马克思主义理论类</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>公共卫生与预防医学类</td>
                                        <td>1</td>
                                        <td>
                                            <span class="zy">预防医学</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>中国语言文学类</td>
                                        <td>4</td>
                                        <td>
                                            <span class="zy">汉语言文学</span>
                                            <span class="zy">汉语言</span>
                                            <span class="zy">古典文献学</span>
                                            <span class="zy">应用语言学</span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="学科评估" name="Tab3">
                            <div class="tab_con">
                                <div class="clear"></div>
                                <table class="subjecthead" cellspacing="0">
                                    <tr>
                                        <td>评级</td>
                                        <td>数量</td>
                                        <td>专业</td>
                                    </tr>
                                </table>
                                <table class="subjecttable" cellspacing="0">
                                    <tr>
                                        <td>A+</td>
                                        <td>20</td>
                                        <td>
                                            <span class="zy">哲学</span>
                                            <span class="zy">艺术学理论</span>
                                            <span class="zy">政治学</span>
                                            <span class="zy">力学</span>
                                            <span class="zy">物理学</span>
                                            <span class="zy">化学</span>
                                            <span class="zy">世界史</span>
                                            <span class="zy">外国语言文学</span>
                                            <span class="zy">生物学</span>
                                            <span class="zy">计算机科学与技术</span>
                                            <span class="zy">统计学</span>
                                            <span class="zy">应用经济学</span>
                                            <span class="zy">地理学</span>
                                            <span class="zy">大气科学</span>
                                            <span class="zy">社会学</span>
                                            <span class="zy">中国语言文学</span>
                                            <span class="zy">心理学</span>
                                            <span class="zy">基础医学</span>
                                            <span class="zy">口腔医学</span>
                                            <span class="zy">考古学</span>
                                            <span class="zy">数学</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>A</td>
                                        <td>10</td>
                                        <td>
                                            <span class="zy">应用经济学</span>
                                            <span class="zy">地理学</span>
                                            <span class="zy">大气科学</span>
                                            <span class="zy">社会学</span>
                                            <span class="zy">中国语言文学</span>
                                            <span class="zy">心理学</span>
                                            <span class="zy">基础医学</span>
                                            <span class="zy">口腔医学</span>
                                            <span class="zy">考古学</span>
                                            <span class="zy">数学</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>A-</td>
                                        <td>7</td>
                                        <td>
                                            <span class="zy">社会学</span>
                                            <span class="zy">中国语言文学</span>
                                            <span class="zy">心理学</span>
                                            <span class="zy">基础医学</span>
                                            <span class="zy">口腔医学</span>
                                            <span class="zy">考古学</span>
                                            <span class="zy">数学</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>B+</td>
                                        <td>14</td>
                                        <td>
                                            <span class="zy">数学</span>
                                            <span class="zy">口腔医学</span>
                                            <span class="zy">考古学</span>
                                            <span class="zy">数学</span>
                                            <span class="zy">社会学</span>
                                            <span class="zy">中国语言文学</span>
                                            <span class="zy">心理学</span>
                                            <span class="zy">基础医学</span>
                                            <span class="zy">口腔医学</span>
                                            <span class="zy">考古学</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>B</td>
                                        <td>14</td>
                                        <td>
                                            <span class="zy">口腔医学</span>
                                            <span class="zy">考古学</span>
                                            <span class="zy">社会学</span>
                                            <span class="zy">中国语言文学</span>
                                            <span class="zy">心理学</span>
                                            <span class="zy">基础医学</span>
                                            <span class="zy">数学</span>
                                            <span class="zy">口腔医学</span>
                                            <span class="zy">考古学</span>
                                            <span class="zy">数学</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>B-</td>
                                        <td>14</td>
                                        <td>
                                            <span class="zy">考古学</span>
                                            <span class="zy">数学</span>
                                            <span class="zy">口腔医学</span>
                                            <span class="zy">考古学</span>
                                            <span class="zy">数学</span>
                                            <span class="zy">社会学</span>
                                            <span class="zy">中国语言文学</span>
                                            <span class="zy">心理学</span>
                                            <span class="zy">基础医学</span>
                                            <span class="zy">口腔医学</span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="招生章程" name="Tab4">
                            <div class="tab_con">
                                <div class="clear"></div>
                                <div class="recruitgeneral">
                                    <div class="recruitlist">
                                        <div class="recruitbox" @click.prevent="ToArticleDetail(1)">
                                            <div class="title">北京大学2024年本科招生章程</div>
                                            <div class="num">26543 次浏览</div>
                                            <div class="time">2024-05-24</div>
                                        </div>
                                        <div class="recruitbox" @click.prevent="ToArticleDetail(2)">
                                            <div class="title nowrap">北京大学2024年强基计划招生简章</div>
                                            <div class="num">12562 次浏览</div>
                                            <div class="time">2024-05-24</div>
                                        </div>
                                        <div class="recruitbox" @click.prevent="ToArticleDetail(3)">
                                            <div class="title">北京大学2024年“筑梦计划”招生简章</div>
                                            <div class="num">26543 次浏览</div>
                                            <div class="time">2024-05-24</div>
                                        </div>
                                        <div class="recruitbox" @click.prevent="ToArticleDetail(4)">
                                            <div class="title">北京大学2024年招收台湾高中毕业生简章</div>
                                            <div class="num">26543 次浏览</div>
                                            <div class="time">2024-05-24</div>
                                        </div>
                                        <div class="recruitbox" @click.prevent="ToArticleDetail(5)">
                                            <div class="title">北京大学2024年高水平运动队招生简章</div>
                                            <div class="num">26543 次浏览</div>
                                            <div class="time">2024-05-24</div>
                                        </div>
                                        <div class="recruitbox" @click.prevent="ToArticleDetail(6)">
                                            <div class="title">北京大学2024年“物理学科卓越人才培养计划”招生简章</div>
                                            <div class="num">26543 次浏览</div>
                                            <div class="time">2024-05-24</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="选考要求" name="Tab5">
                            <div class="tab_con">
                                <div class="clear"></div>
                                <table class="examhead" cellspacing="0">
                                    <tr>
                                        <td>专业名称</td>
                                        <td>选考要求</td>
                                        <td>包含专业</td>
                                    </tr>
                                </table>
                                <table class="examtable" cellspacing="0">
                                    <tr>
                                        <td>文科试验班(心理学类,中国语言文学类,历史学类,社会学类,哲学类,经济学类,工商管理类,公共管理类,新闻传播学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>考古学,法学,城乡规划</td>
                                    </tr>
                                    <tr>
                                        <td>人文科学试验班(戏剧与影视学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>艺术史论</td>
                                    </tr>
                                    <tr>
                                        <td>工科试验班</td>
                                        <td>首选: 仅物理; 再选: 化学(1门必选)</td>
                                        <td>工程力学(工程结构分析),机器人工程,航空航天工程,生物医学工程,材料科学与工程,理论与应用力学,能源与环境系统工程</td>
                                    </tr>
                                    <tr>
                                        <td>文科试验班(心理学类,中国语言文学类,历史学类,社会学类,哲学类,经济学类,工商管理类,公共管理类,新闻传播学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>考古学,法学,城乡规划</td>
                                    </tr>
                                    <tr>
                                        <td>人文科学试验班(戏剧与影视学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>艺术史论</td>
                                    </tr>
                                    <tr>
                                        <td>工科试验班</td>
                                        <td>首选: 仅物理; 再选: 化学(1门必选)</td>
                                        <td>工程力学(工程结构分析),机器人工程,航空航天工程,生物医学工程,材料科学与工程,理论与应用力学,能源与环境系统工程</td>
                                    </tr>
                                    <tr>
                                        <td>文科试验班(心理学类,中国语言文学类,历史学类,社会学类,哲学类,经济学类,工商管理类,公共管理类,新闻传播学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>考古学,法学,城乡规划</td>
                                    </tr>
                                    <tr>
                                        <td>人文科学试验班(戏剧与影视学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>艺术史论</td>
                                    </tr>
                                    <tr>
                                        <td>工科试验班</td>
                                        <td>首选: 仅物理; 再选: 化学(1门必选)</td>
                                        <td>工程力学(工程结构分析),机器人工程,航空航天工程,生物医学工程,材料科学与工程,理论与应用力学,能源与环境系统工程</td>
                                    </tr>
                                    <tr>
                                        <td>文科试验班(心理学类,中国语言文学类,历史学类,社会学类,哲学类,经济学类,工商管理类,公共管理类,新闻传播学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>考古学,法学,城乡规划</td>
                                    </tr>
                                    <tr>
                                        <td>人文科学试验班(戏剧与影视学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>艺术史论</td>
                                    </tr>
                                    <tr>
                                        <td>工科试验班</td>
                                        <td>首选: 仅物理; 再选: 化学(1门必选)</td>
                                        <td>工程力学(工程结构分析),机器人工程,航空航天工程,生物医学工程,材料科学与工程,理论与应用力学,能源与环境系统工程</td>
                                    </tr>
                                    <tr>
                                        <td>文科试验班(心理学类,中国语言文学类,历史学类,社会学类,哲学类,经济学类,工商管理类,公共管理类,新闻传播学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>考古学,法学,城乡规划</td>
                                    </tr>
                                    <tr>
                                        <td>人文科学试验班(戏剧与影视学类)</td>
                                        <td>首选: 物理或历史均可; 再选: 不限</td>
                                        <td>艺术史论</td>
                                    </tr>
                                    <tr>
                                        <td>工科试验班</td>
                                        <td>首选: 仅物理; 再选: 化学(1门必选)</td>
                                        <td>工程力学(工程结构分析),机器人工程,航空航天工程,生物医学工程,材料科学与工程,理论与应用力学,能源与环境系统工程</td>
                                    </tr>
                                </table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="招生计划" name="Tab6">
                            <div class="tab_con">
                                <div class="clear"></div>
                                <table class="planhead" cellspacing="0">
                                    <tr>
                                        <td>院校名称</td>
                                        <td>代码</td>
                                        <td>专业名称</td>
                                        <td>计划人数</td>
                                        <td>学费</td>
                                        <td>学制（年）</td>
                                        <td>批次</td>
                                    </tr>
                                </table>
                                <table class="plantable" cellspacing="0">
                                    <tr>
                                        <td>北京大学</td>
                                        <td>01</td>
                                        <td>文科试验班((元培)(包含专业:中国语言文学类、考古学、历史学类、哲学类、社会学类、法学、信息管理与信息系统、新闻传播学类、经济学类、工商管理类、公共管理类))</td>
                                        <td>1</td>
                                        <td>5300</td>
                                        <td>4</td>
                                        <td>本一批</td>
                                    </tr>
                                    <tr>
                                        <td>北京大学</td>
                                        <td>02</td>
                                        <td>新闻传播学类((包含专业:新闻学、广播电视学、广告学、编辑出版学)) </td>
                                        <td>1</td>
                                        <td>5000</td>
                                        <td>4</td>
                                        <td>本一批</td>
                                    </tr>
                                    <tr>
                                        <td>北京大学</td>
                                        <td>03</td>
                                        <td>信息管理与信息系统</td>
                                        <td>1</td>
                                        <td>5000</td>
                                        <td>4</td>
                                        <td>本一批</td>
                                    </tr>
                                    <tr>
                                        <td>北京大学</td>
                                        <td>02</td>
                                        <td>新闻传播学类((包含专业:新闻学、广播电视学、广告学、编辑出版学)) </td>
                                        <td>1</td>
                                        <td>5000</td>
                                        <td>4</td>
                                        <td>本一批</td>
                                    </tr>
                                    <tr>
                                        <td>北京大学</td>
                                        <td>03</td>
                                        <td>信息管理与信息系统</td>
                                        <td>1</td>
                                        <td>5000</td>
                                        <td>4</td>
                                        <td>本一批</td>
                                    </tr>
                                    <tr>
                                        <td>北京大学</td>
                                        <td>01</td>
                                        <td>文科试验班((元培)(包含专业:中国语言文学类、考古学、历史学类、哲学类、社会学类、法学、信息管理与信息系统、新闻传播学类、经济学类、工商管理类、公共管理类))</td>
                                        <td>1</td>
                                        <td>5300</td>
                                        <td>4</td>
                                        <td>本一批</td>
                                    </tr>
                                </table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="录取分数线" name="Tab7">
                            <div class="tab_con">
                                <div class="con_box">
                                    <div class="title"><span :class="(ScoreLineBox==1?'on':'no')" @click.prevent="ScoreLineBtn(1)">院校分数线</span><span :class="(ScoreLineBox==2?'on':'no')" @click.prevent="ScoreLineBtn(2)">专业分数线</span></div>
                                    <div class="content" v-if="ScoreLineBox==1">
                                        <table class="collegelinehead" cellspacing="0">
                                            <tr>
                                                <td>院校名称</td>
                                                <td>年份</td>
                                                <td>最低分</td>
                                                <td>平均分</td>
                                                <td>最低位次</td>
                                                <td>线差</td>
                                                <td>录取人数</td>
                                                <td>批次</td>
                                            </tr>
                                        </table>
                                        <table class="collegelinetable" cellspacing="0">
                                            <tr>
                                                <td>北京大学</td>
                                                <td>2024</td>
                                                <td>646</td>
                                                <td>646</td>
                                                <td>43</td>
                                                <td></td>
                                                <td>1</td>
                                                <td>提前批本科</td>
                                            </tr>
                                            <tr>
                                                <td>北京大学</td>
                                                <td>2023</td>
                                                <td>646</td>
                                                <td>646</td>
                                                <td>43</td>
                                                <td></td>
                                                <td>1</td>
                                                <td>提前批本科</td>
                                            </tr>
                                            <tr>
                                                <td>北京大学</td>
                                                <td>2022</td>
                                                <td>646</td>
                                                <td>646</td>
                                                <td>43</td>
                                                <td></td>
                                                <td>1</td>
                                                <td>提前批本科</td>
                                            </tr>
                                            <tr>
                                                <td>北京大学</td>
                                                <td>2021</td>
                                                <td>646</td>
                                                <td>646</td>
                                                <td>43</td>
                                                <td></td>
                                                <td>1</td>
                                                <td>提前批本科</td>
                                            </tr>
                                            <tr>
                                                <td>北京大学</td>
                                                <td>2020</td>
                                                <td>646</td>
                                                <td>646</td>
                                                <td>43</td>
                                                <td></td>
                                                <td>1</td>
                                                <td>提前批本科</td>
                                            </tr>
                                            <tr>
                                                <td>北京大学</td>
                                                <td>2019</td>
                                                <td>646</td>
                                                <td>646</td>
                                                <td>43</td>
                                                <td>177</td>
                                                <td>1</td>
                                                <td>提前批本科</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="content" v-if="ScoreLineBox==2">
                                        <table class="majorlinehead" cellspacing="0">
                                            <tr>
                                                <td>院校名称</td>
                                                <td>专业名称</td>
                                                <td>最低分</td>
                                                <td>平均分</td>
                                                <td>最低位次</td>
                                                <td>线差</td>
                                                <td>录取人数</td>
                                                <td>批次</td>
                                            </tr>
                                        </table>
                                        <table class="majorlinetable" cellspacing="0">
                                            <tr>
                                                <td>北京大学</td>
                                                <td>俄语</td>
                                                <td>646</td>
                                                <td>646</td>
                                                <td>43</td>
                                                <td></td>
                                                <td>1</td>
                                                <td>提前批本科</td>
                                            </tr>
                                            <tr>
                                                <td>北京大学</td>
                                                <td>马克思主义理论类 </td>
                                                <td>646</td>
                                                <td>646</td>
                                                <td>43</td>
                                                <td></td>
                                                <td>1</td>
                                                <td>提前批本科</td>
                                            </tr>
                                            <tr>
                                                <td>北京大学</td>
                                                <td>公共管理类((包含专业:城市管理、行政管理、行政管理(政治与行政))) </td>
                                                <td>646</td>
                                                <td>646</td>
                                                <td>43</td>
                                                <td></td>
                                                <td>1</td>
                                                <td>提前批本科</td>
                                            </tr>
                                            <tr>
                                                <td>北京大学</td>
                                                <td>国际政治((国政、外交学、国际政经)) </td>
                                                <td>646</td>
                                                <td>646</td>
                                                <td>43</td>
                                                <td></td>
                                                <td>1</td>
                                                <td>提前批本科</td>
                                            </tr>
                                            <tr>
                                                <td>北京大学</td>
                                                <td>法学 </td>
                                                <td>646</td>
                                                <td>646</td>
                                                <td>43</td>
                                                <td></td>
                                                <td>1</td>
                                                <td>提前批本科</td>
                                            </tr>
                                            <tr>
                                                <td>北京大学</td>
                                                <td>英语((招收英语语种考生,外语口试)) </td>
                                                <td>646</td>
                                                <td>646</td>
                                                <td>43</td>
                                                <td></td>
                                                <td>1</td>
                                                <td>提前批本科</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>

        <!-- 底部 -->
        <PFooter></PFooter>

    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import { getStore,setStore } from "@/utils/utils";
import API from "@/api/config";
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return{
            TabActiveId:'Tab1',
            LeftNavStatus:true,
            left_nav_class:'',
            DetailLayerClass:'',
            DetailLayerConClass:'',
            DetailInfoLayerShow:false,
            DetailTimeLayerShow:false,
            OrderNoBox:1,
            AbilityBox:1,
            ScoreLineBox:1,
        }
    },
    mounted(){
        this.GetNewsInfoDetail();// 获取详情
        this.CalcLeftNav();// 计算左侧菜单位置及高度

        //监听滚动事件
        window.addEventListener('scroll', () => {
            let ddeht = document.documentElement.scrollTop || document.body.scrollTop;
            if (ddeht > 360) {
                document.getElementById('college_left_nav').style.display='block';
            }
            else {
                document.getElementById('college_left_nav').style.display='none';
            }
        }, true);
    },
    methods:{
        // 获取详情
        GetNewsInfoDetail(){
            // API.Web.GetNewsInfoDetail({id:this.$route.query.id}).then(res => {
            //     if(res.Code===0){
            //         this.newsdata = res.Data;
            //         this.newsdata.RichTextContent.replace(/\<video/gi, "<video width='100%'");
            //     }
            // });
        },
        // 打开质量报告
        OpenReport(url) {
            if (url) {
                window.open(url)
            } else {
                this.$messageTips('warning', '暂无相关报告！')
            }
        },
        // 计算左侧菜单位置及高度
        CalcLeftNav(){
            let pagewidth = document.body.clientWidth || document.documentElement.clientWidth;
            let conwidth = document.getElementById("college_detail_box").clientWidth;
            let num = ((pagewidth-conwidth) / 2);
            if(num>78){
                document.getElementById('college_left_nav').style.left= (num - 68)+'px';
            }
            else{
                document.getElementById('college_left_nav').style.left='0px';
            }
            let pageheight = window.innerHeight;
            if(pageheight>500){
                document.getElementById("left_nav_box").style.height = '380px';
            }
            else{
                document.getElementById("left_nav_box").style.height = (pageheight-125)+'px';
            }
        },
        // 左侧菜单点击
        LeftNavBtn(id){
            this.TabActiveId = id;
            window.scrollTo(0, 362);
        },
        // 左侧菜单收起/打开
        LeftNavDownUp(){
            if(this.LeftNavStatus){
                this.left_nav_class='animate_up';
                this.LeftNavStatus=false;
            }
            else{
                this.left_nav_class='animate_down';
                this.LeftNavStatus=true;
            }
        },
        // 打开更多内容 院校介绍
        DetailInfoLayerOpen(){
            this.DetailInfoLayerShow=true;
            this.DetailLayerClass='layerbackshow';
            this.DetailLayerConClass='animate_open';
        },
        // 关闭更多内容 院校介绍
        DetailInfoLayerClose(){
            this.DetailLayerClass='layerbackhide';
            this.DetailLayerConClass='animate_close';
            let st=setTimeout(()=>{
                this.DetailInfoLayerShow=false;
                clearTimeout(st);
            },300);
        },
        // 打开更多内容 历史沿革
        DetailTimeLayerOpen(){
            this.DetailTimeLayerShow=true;
            this.DetailLayerClass='layerbackshow';
            this.DetailLayerConClass='animate_open';
        },
        // 关闭更多内容 历史沿革
        DetailTimeLayerClose(){
            this.DetailLayerClass='layerbackhide';
            this.DetailLayerConClass='animate_close';
            let st=setTimeout(()=>{
                this.DetailTimeLayerShow=false;
                clearTimeout(st);
            },300);
        },
        // 院校排名、综合评级 切换
        OrderNoBtn(val){
            this.OrderNoBox=val;
        },
        // 科研实力 切换
        AbilityBoxBtn(val){
            this.AbilityBox=val;
        },
        // 院校分数线、专业分数线 切换
        ScoreLineBtn(val){
            this.ScoreLineBox=val;
        },
        // 招生章程详情
        ToArticleDetail(id){
            this.$router.push("/articledetail?id="+id);
        }
    }
}
</script>
<style lang="less" scoped>
.college_detail_head{
    width: 100%;
    height: 240px;
    .head_back{
        width: 100%;
        height: 100%;
        background-color: rgba(36,53,71,0.9);
        .head_auto{
            max-width: 1420px;
            margin: auto;
            user-select: none;
            height: 100%;
            .head_box{
                margin: 0px 20px;
                height: 100%;
                .head_top{
                    width: 100%;
                    height: 180px;
                    overflow: hidden;
                    .logo_box{
                        width: 140px;
                        height: 140px;
                        background-color: rgba(255,255,255,0.2);
                        border-radius: 70px;
                        margin-top: 20px;
                        float: left;
                        .logo{
                            width: 120px;
                            height: 120px;
                            margin: 10px;
                            overflow: hidden;
                            background-color: rgba(255,255,255,1);
                            border-radius: 60px;
                            .img{
                                width: 100px;
                                height: 100px;
                                border-radius: 50px;
                                margin: 10px;
                            }
                        }
                    }
                    .txt_box{
                        width: calc(100% - 320px);
                        float: left;
                        margin-left: 30px;
                        .cname{
                            color: #fff;
                            margin-top: 30px;
                            .name{
                                font-size: 30px;
                                letter-spacing: 2px;
                            }
                            .type{
                                font-size: 16px;
                                margin-left: 20px;
                            }
                        }
                        .dress{
                            width: 100%;
                            line-height: 20px;
                            margin-top: 10px;
                            color: #b1b1b1;
                            font-size: 13px;
                            span{
                                display: inline-block;
                                margin-right: 20px;
                                i{
                                    margin-right: 4px;
                                }
                            }
                        }
                        .tip_box{
                            margin-top: 20px;
                            height: 26px;
                            overflow: hidden;
                            span{
                                background-color: rgba(255, 255, 255,0.18);
                                display: inline-block;
                                padding: 1px 10px;
                                color: #d3d3d3;
                                font-size: 12px;
                                border-radius: 10px;
                                margin-right: 5px;
                                margin-bottom: 5px;
                            }
                        }
                    }
                    .btn_box{
                        width: 150px;
                        float: right;
                        margin-top: 20px;
                        .btn{
                            padding: 6px 0px;
                            color: #c0c0c0;
                            font-size: 14px;
                            border-radius: 3px;
                            border: 1px solid #7c7c7c;
                            margin: auto;
                            text-align: center;
                            letter-spacing: 1px;
                            margin-bottom: 15px;
                            overflow: hidden;
                            transition: all 0.2s;
                            -webkit-transition: all 0.2s;
                        }
                        .btn:hover{
                            cursor: pointer;
                            color: #d3d1d1;
                            border: 1px solid #bbbaba;
                            background-color: rgba(0,0,0,0.5);
                        }
                    }
                }
            }
        }
    }
}
.college_left_nav{
    width: 78px;
    background-color: #fff;
    border-radius: 3px;
    position: fixed;
    top: 80px;
    overflow: hidden;
    height: auto;
    display: none;
    user-select: none;
    z-index: 20;
    .open{
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        color: #eee;
        font-size: 12px;
        background-color: rgba(var(--themecolor),0.85);
        i{
            color: #e6e6e6;
            font-size: 12px;
            margin-right: 3px;
        }
        i.up{
            transform:rotate(-90deg);
            -webkit-transform:rotate(-90deg);
        }
        i.down{
            transform:rotate(90deg);
            -webkit-transform:rotate(90deg);
        }
    }
    .open:hover{
        background-color: rgba(var(--themecolor),1);
    }
    .left_nav_box{
        border: 1px solid #c7c7c7;
        border-top: none;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        .nav{
            color: #666;
            font-size: 14px;
            text-align: center;
            line-height: 18px;
            border-bottom: 1px solid #ddd;
            padding: 12px 10px;
            cursor: pointer;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
        }
        .nav.on{
            color: #444;
            background-color: rgba(var(--themecolor),0.2);
        }
        .nav:hover{
            color: #444;
            background-color: rgba(var(--themecolor),0.3);
        }
        .nav:last-child{
            border-bottom:none;
        }
    }
}
.college_left_nav.animate_up{
    height: 30px;
}
.college_left_nav.animate_down{
    height: auto;
}
.college_detail_box{
    max-width: 1420px;
    margin: 0px auto 50px;
    user-select: none;
    .college_detail_con{
        margin: 0px 20px;
        .tab_box{
            overflow: hidden;
            margin-top: -39px;
            .tab_con{
                min-height: 500px;
                border: 1px solid #d1d1d1;
                border-top: none;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                background-color: #fff;
                .con_box{
                    margin: 0 20px;
                    padding-top: 30px;
                    .title{
                        width: 100%;
                        height: 30px;
                        border-bottom: 1px solid #ddd;
                        border-bottom-right-radius: 5px;
                        span{
                            color: #666;
                            font-size: 14px;
                            height: 29px;
                            display: inline-block;
                            line-height: 29px;
                            padding: 0px 20px;
                            border-top: 1px solid #ddd;
                            border-right: 1px solid #ddd;
                            background-color: #fff;
                            border-bottom: 1px solid #ddd;
                        }
                        span.no:hover{
                            cursor: pointer;
                            color: rgba(var(--themecolor),0.9);
                            background-color: rgba(var(--themecolor),0.1);
                        }
                        span.on{
                            color: #444;
                            border-bottom: 1px solid #fff;
                        }
                        span:first-child{
                            border-top-left-radius: 5px;
                            border-left: 1px solid #ddd;
                        }
                        span:last-child{
                            border-top-right-radius: 5px;
                        }
                    }
                    .content{
                        border: 1px solid #ddd;
                        border-top: none;
                        border-top-right-radius: 5px;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                        min-height: 100px;
                        position: relative;
                        overflow: hidden;
                        margin-bottom: 30px;
                        .info{
                            color: #666;
                            font-size: 14px;
                            text-indent: 28px;
                            line-height: 24px;
                            margin: 20px 20px 10px;
                            max-height: 186px;
                            overflow: hidden;
                        }
                        .info.nonum{
                            max-height: none;
                        }
                        .imgbox{
                            margin: 20px 10px 15px;
                            .img{
                                width: calc(100% / 4 - 20px);
                                margin: 0px 10px;
                                height: 180px;
                                display: inline-block;
                                border-radius: 5px;
                            }
                        }
                        .orderbox{
                            margin: 20px 20px 15px;
                            border: 1px solid #ddd;
                            border-radius: 5px;
                            .type{
                                width: 100%;
                                height: 50px;
                                background-color: rgba(var(--themecolor),0.15);
                                .t{
                                    width: calc(100% / 7 - 1px);
                                    display: inline-block;
                                    text-align: center;
                                    line-height: 50px;
                                    color: #333;
                                    font-size: 16px;
                                    border-left: 1px solid #fff;
                                }
                                .t:first-child{
                                    border-left: none;
                                }
                            }
                            .order{
                                width: 100%;
                                height: 80px;
                                .o{
                                    width: calc(100% / 7 - 1px);
                                    display: inline-block;
                                    text-align: center;
                                    line-height: 80px;
                                    color: rgba(var(--themecolor),0.8);
                                    font-size: 20px;
                                    border-left: 1px solid #e7e7e7;
                                }
                                .o:first-child{
                                    border-left: none;
                                }
                            }
                        }
                        .proportionbox{
                            margin: 20px 10px 15px;
                            .pbox{
                                width: calc(100% / 3 - 22px);
                                margin: 0px 10px;
                                height: 160px;
                                float: left;
                                border-radius: 5px;
                                border: 1px solid #e4e4e4;
                                .tit{
                                    width: 100%;
                                    height: 50px;
                                    background-color: rgba(var(--themecolor),0.15);
                                    color: #555;
                                    font-size: 16px;
                                    line-height: 50px;
                                    text-align: center;
                                }
                                .num{
                                    margin: 30px 20px 0;
                                    color: #444;
                                    font-size: 11px;
                                    display: block;
                                    height: 20px;
                                    line-height: 20px;
                                    .item{
                                        width: 100%;
                                        display: block;
                                        margin-bottom: 10px;
                                        span{
                                            float: left;
                                        }
                                    }
                                    .n1{
                                        width: calc(100% - 100px);
                                        display: inline-block;
                                        height: 20px;
                                        background-color: #e4e4e4;
                                        border-radius: 10px;
                                        position: relative;
                                        overflow: hidden;
                                        .n2{
                                            position: absolute;
                                            height: 100%;
                                            background-color: rgba(var(--themecolor),0.75);
                                            border-radius: 10px;
                                        }
                                    }
                                }
                            }
                        }
                        .timeline{
                            margin: 20px 20px 15px;
                            border: 1px solid #ddd;
                            border-radius: 5px;
                            position: relative;
                            overflow: hidden;
                            .lbox{
                                margin: 20px;
                                border-left: 1px solid #ddd;
                                .line{
                                    margin: 0px 20px 30px;
                                    position: relative;
                                    .ra{
                                        width: 9px;
                                        height: 9px;
                                        background-color: rgba(var(--themecolor),1);
                                        border-radius: 10px;
                                        position: absolute;
                                        left: -25px;
                                        top: 5px;
                                        z-index: 1;
                                    }
                                    .time{
                                        color: #888;
                                        font-size: 14px;
                                    }
                                    .text{
                                        color: #555;
                                        font-size: 14px;
                                        margin: 10px 20px 0px;
                                    }
                                }
                            }
                        }
                        .more{
                            position: absolute;
                            left: 0px;
                            bottom: 0px;
                            height: 86px;
                            width: 100%;
                            .back{
                                width: 100%;
                                height: 56px;
                                background: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0.01));
                            }
                            .btn{
                                width: 100%;
                                height: 30px;
                                background-color: #fff;
                                .b{
                                    color: #666;
                                    font-size: 14px;
                                    margin: 0px auto 0;
                                    width: 150px;
                                    background-color: #fff;
                                    text-align: center;
                                    cursor: pointer;
                                    i{
                                        margin-left: 5px;
                                        transform:rotate(90deg);
                                        -webkit-transform:rotate(90deg);
                                    }
                                }
                                .b:hover{
                                    color: rgba(var(--themecolor),0.75);
                                }
                            }
                        }
                    }
                }
                .subjecthead{
                    margin: 30px 20px 0px;
                    border-radius: 5px;
                    background-color: #fff;
                    display: block;
                    table-layout: fixed;
                    border: 1px solid #ddd;
                    border-bottom-left-radius:0;
                    border-bottom-right-radius:0;
                    overflow: hidden;
                    background-color: rgba(var(--themecolor),0.1);
                    tr{
                        display:block;
                        td{
                            color: #555;
                            font-size: 16px;
                            padding: 15px 0px;
                            text-align: center;
                            border-left: 1px solid #ddd;
                            display: inline-block;
                        }
                        td:nth-child(1){
                            border-left: none;
                            width: 220px;
                        }
                        td:nth-child(2){
                            width: 100px;
                        }
                        td:nth-child(3){
                            width: calc(100% - 322px);
                        }
                    }
                }
                .subjecttable{
                    margin: 0px 20px 30px;
                    border-radius: 5px;
                    background-color: #fff;
                    table-layout: fixed;
                    border: 1px solid #ddd;
                    border-top: none;
                    border-top-left-radius:0;
                    border-top-right-radius:0;
                    overflow: hidden;
                    tr{
                        td{
                            border-left: 1px solid #ddd;
                            color: #777;
                            font-size: 14px;
                            padding: 30px 0px;
                            text-align: center;
                            border-bottom: 1px solid #ddd;
                            .zy{
                                padding: 2px 10px;
                                color: #6e6c6c;
                                font-size: 14px;
                                margin: 5px 10px;
                                display: inline-block;
                            }
                        }
                        td:first-child{
                            border-left:none;
                            width: 200px;
                            padding: 0 10px;
                            color: rgba(var(--themecolor),1);
                            font-size: 20px;
                        }
                        td:last-child{
                            text-align: left;
                        }
                        td:nth-child(2){
                            width: 100px;
                        }
                        td:nth-child(3){
                            width: calc(100% - 322px);
                        }
                    }
                    tr:last-child td{
                        border-bottom:none;
                    }
                    tr:nth-child(2n+1){
                        background-color: #fdfdfd;
                    }
                }
                .examhead{
                    margin: 30px 20px 0px;
                    border-radius: 5px;
                    background-color: #fff;
                    display: block;
                    table-layout: fixed;
                    border: 1px solid #ddd;
                    border-bottom-left-radius:0;
                    border-bottom-right-radius:0;
                    overflow: hidden;
                    background-color: rgba(var(--themecolor),0.1);
                    tr{
                        display:block;
                        td{
                            color: #555;
                            font-size: 16px;
                            padding: 15px 0px;
                            text-align: center;
                            border-left: 1px solid #ddd;
                            display: inline-block;
                        }
                        td:nth-child(1){
                            border-left: none;
                            width: 260px;
                        }
                        td:nth-child(2){
                            width: 260px;
                        }
                        td:nth-child(3){
                            width: calc(100% - 522px);
                        }
                    }
                }
                .examtable{
                    table-layout: fixed !important;
                    margin: 0px 20px 30px;
                    border-radius: 5px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-top: none;
                    border-top-left-radius:0;
                    border-top-right-radius:0;
                    overflow: hidden;
                    width: calc(100% - 40px);
                    tr{
                        td{
                            border-left: 1px solid #ddd;
                            color: #777;
                            text-align: center;
                            border-bottom: 1px solid #ddd;
                            font-size: 14px;
                            line-height: 22px;
                            padding: 20px 10px;
                        }
                        td:first-child{
                            border-left:none;
                            width: 240px;
                        }
                        td:nth-child(2){
                            width: 240px;
                        }
                        td:nth-child(3){
                            width: calc(100% - 522px);
                        }
                    }
                    tr:last-child td{
                        border-bottom:none;
                    }
                    tr:nth-child(2n+1){
                        background-color: #fdfdfd;
                    }
                }
                .planhead{
                    margin: 30px 20px 0px;
                    border-radius: 5px;
                    background-color: #fff;
                    display: block;
                    table-layout: fixed;
                    border: 1px solid #ddd;
                    border-bottom-left-radius:0;
                    border-bottom-right-radius:0;
                    overflow: hidden;
                    background-color: rgba(var(--themecolor),0.1);
                    tr{
                        display:block;
                        td{
                            color: #555;
                            font-size: 16px;
                            padding: 15px 0px;
                            text-align: center;
                            border-left: 1px solid #ddd;
                            display: inline-block;
                        }
                        td:nth-child(1){
                            border-left: none;
                            width: 170px;
                        }
                        td:nth-child(2),td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7){
                            width: 120px;
                        }
                        td:nth-child(3){
                            width: calc(100% - 776px);
                        }
                    }
                }
                .plantable{
                    table-layout: fixed !important;
                    margin: 0px 20px 30px;
                    border-radius: 5px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-top: none;
                    border-top-left-radius:0;
                    border-top-right-radius:0;
                    overflow: hidden;
                    width: calc(100% - 40px);
                    tr{
                        td{
                            border-left: 1px solid #ddd;
                            color: #777;
                            text-align: center;
                            border-bottom: 1px solid #ddd;
                            font-size: 14px;
                            line-height: 22px;
                            padding: 20px 10px;
                        }
                        td:first-child{
                            border-left:none;
                            width: 150px;
                        }
                        td:nth-child(2),td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7){
                            width: 100px;
                        }
                    }
                    tr:last-child td{
                        border-bottom:none;
                    }
                    tr:nth-child(2n+1){
                        background-color: #fdfdfd;
                    }
                }
                .collegelinehead{
                    margin: 30px 20px 0px;
                    border-radius: 5px;
                    background-color: #fff;
                    display: block;
                    table-layout: fixed;
                    border: 1px solid #ddd;
                    border-bottom-left-radius:0;
                    border-bottom-right-radius:0;
                    overflow: hidden;
                    background-color: rgba(var(--themecolor),0.1);
                    tr{
                        display:block;
                        td{
                            color: #555;
                            font-size: 16px;
                            padding: 15px 0px;
                            text-align: center;
                            border-left: 1px solid #ddd;
                            display: inline-block;
                        }
                        td:nth-child(1){
                            width: 170px;
                            border-left: none;
                        }
                        td:nth-child(2),td:nth-child(3),td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7),td:nth-child(8){
                            width: calc((100% - 177px) / 7);
                        }
                    }
                }
                .collegelinetable{
                    table-layout: fixed !important;
                    margin: 0px 20px 30px;
                    border-radius: 5px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-top: none;
                    border-top-left-radius:0;
                    border-top-right-radius:0;
                    overflow: hidden;
                    width: calc(100% - 40px);
                    tr{
                        td{
                            border-left: 1px solid #ddd;
                            color: #777;
                            text-align: center;
                            border-bottom: 1px solid #ddd;
                            font-size: 14px;
                            line-height: 22px;
                            padding: 20px 10px;
                        }
                        td:nth-child(1){
                            border-left:none;
                            width: 150px;
                        }
                        td:nth-child(2),td:nth-child(3),td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7),td:nth-child(8){
                            width: calc(100% / 7);
                        }
                    }
                    tr:last-child td{
                        border-bottom:none;
                    }
                    tr:nth-child(2n+1){
                        background-color: #fdfdfd;
                    }
                }
                .majorlinehead{
                    margin: 30px 20px 0px;
                    border-radius: 5px;
                    background-color: #fff;
                    display: block;
                    table-layout: fixed;
                    border: 1px solid #ddd;
                    border-bottom-left-radius:0;
                    border-bottom-right-radius:0;
                    overflow: hidden;
                    background-color: rgba(var(--themecolor),0.1);
                    tr{
                        display:block;
                        td{
                            color: #555;
                            font-size: 16px;
                            padding: 15px 0px;
                            text-align: center;
                            border-left: 1px solid #ddd;
                            display: inline-block;
                        }
                        td:nth-child(1){
                            border-left: none;
                        }
                        td:nth-child(2){
                            width: 170px;
                        }
                        td:nth-child(1),td:nth-child(3),td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7),td:nth-child(8){
                            width: calc((100% - 177px) / 7);
                        }
                    }
                }
                .majorlinetable{
                    table-layout: fixed !important;
                    margin: 0px 20px 30px;
                    border-radius: 5px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-top: none;
                    border-top-left-radius:0;
                    border-top-right-radius:0;
                    overflow: hidden;
                    width: calc(100% - 40px);
                    tr{
                        td{
                            border-left: 1px solid #ddd;
                            color: #777;
                            text-align: center;
                            border-bottom: 1px solid #ddd;
                            font-size: 14px;
                            line-height: 22px;
                            padding: 20px 10px;
                        }
                        td:first-child{
                            border-left:none;
                        }
                        td:nth-child(2){
                            width: 150px;
                        }
                        td:nth-child(1),td:nth-child(3),td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7),td:nth-child(8){
                            width: calc(100% / 7);
                        }
                    }
                    tr:last-child td{
                        border-bottom:none;
                    }
                    tr:nth-child(2n+1){
                        background-color: #fdfdfd;
                    }
                }
                .recruitgeneral{
                    margin: 30px 20px 30px;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    overflow: hidden;
                    background-color: #fff;
                    .recruitlist{
                        margin: 20px 20px 0;
                        .recruitbox{
                            width: 100%;
                            height: 60px;
                            line-height: 60px;
                            margin-bottom: 20px;
                            background-color: #f5f5f5;
                            border-radius: 5px;
                            color: #555;
                            font-size: 16px;
                            .title{
                                text-indent: 20px;
                                transition: all 0.3s;
                                -webkit-transition: all 0.3s;
                                width: calc(100% - 270px);
                                height: 100%;
                                float: left;
                            }
                            .num{
                                display: inline-block;
                                width: 150px;
                            }
                            .time{
                                display: inline-block;
                                width: 120px;
                            }
                        }
                        .recruitbox:hover{
                            cursor: pointer;
                            color: rgb(var(--themecolor));
                            background-color: #f1f0f0;
                        }
                    }
                }
            }
        }
    }
}
.detail_layer_back{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
    background-color: rgba(0,0,0,0.4);
    .detail_layer_box{
        max-width: 1420px;
        margin: 30px auto;
        height: calc(100% - 60px);
        .layer_con{
            margin: 0px 20px;
            background-color: #fff;
            height: calc(100% - 40px);
            position: relative;
            top: 20px;
            border-radius: 5px;
            overflow: hidden;
            .bt{
                width: 100%;
                height: 40px;
                border-bottom: 1px solid #ddd;
                background-color: #fff;
                position: relative;
                .tt{
                    color: #666;
                    font-size: 16px;
                    line-height: 40px;
                    margin-left: 20px;
                }
                i{
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 1;
                    display: inline-block;
                    width: 40px;
                    height: 100%;
                    line-height: 40px;
                    color: #888;
                    font-size: 20px;
                    text-align: center;
                    cursor: pointer;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                }
                i:hover{
                    color: rgba(var(--themecolor),1);
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                }
            }
            .con{
                color: #666;
                font-size: 14px;
                text-indent: 28px;
                line-height: 28px;
                margin: 20px 30px 20px;
                height: calc(100% - 140px);
            }
            .back{
                width: 100%;
                height: 60px;
                border-top: 1px solid #ddd;
                background-color: #fff;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 1;
                .btn{
                    width: 150px;
                    height: 40px;
                    margin: 10px auto 0;
                    background-color: rgba(var(--themecolor),0.75);
                    color: #fff;
                    font-size: 14px;
                    line-height: 40px;
                    border-radius: 5px;
                    text-align: center;
                }
                .btn:hover{
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
        .layer_con.animate_open{
            animation: animate_open 0.3s ease 0s 1;
            -webkit-animation: animate_open 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes animate_open {
                from {
                    top: -200px;
                }
                to {
                    top: 20px;
                }
            }
            @keyframes animate_open {
                from {
                    top: -200px;
                }
                to {
                    top: 20px;
                }
            }
        }
        .layer_con.animate_close{
            animation: animate_close 0.3s ease 0s 1;
            -webkit-animation: animate_close 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes animate_close {
                from {
                    top: 20px;
                }
                to {
                    top: -200px;
                }
            }
            @keyframes animate_close {
                from {
                    top: 20px;
                }
                to {
                    top: -200px;
                }
            }
        }
    }
}
.detail_layer_back.layerbackshow {
    animation: layerbackshow 0.3s ease 0s 1;
    -webkit-animation: layerbackshow 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    @-webkit-keyframes layerbackshow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes layerbackshow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
.detail_layer_back.layerbackhide {
    animation: layerbackhide 0.3s ease 0s 1;
    -webkit-animation: layerbackhide 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    @-webkit-keyframes layerbackhide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    @keyframes layerbackhide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
}
</style>
<style lang="less">
.Class_RichTextContent img,.Class_RichTextContent video{
    width: auto;
    max-width: 100%;
    display: block;
}
.college_detail_box .college_detail_con .tab_box .el-tabs__header{
    margin: 0;
}
.college_detail_box .college_detail_con .tab_box .el-tabs__nav{
    z-index: 0;
    margin-left: 1px;
}
.college_detail_box .college_detail_con .tab_box .el-tabs__item{
    color: #eee;
    background-color: rgba(var(--themecolor),0.75);
    padding: 0 30px;
}
.college_detail_box .college_detail_con .tab_box .el-tabs__item:hover{
    color: #fff;
    background-color: rgba(var(--themecolor),0.55);
}
.college_detail_box .college_detail_con .tab_box .el-tabs--card > .el-tabs__header{
    border-bottom: none;
}
.college_detail_box .college_detail_con .tab_box .el-tabs--card > .el-tabs__header .el-tabs__nav{
    border: none;
    overflow: hidden;
}
.college_detail_box .college_detail_con .tab_box .el-tabs--card > .el-tabs__header .el-tabs__item:first-child{
    border-left: none;
}
.college_detail_box .college_detail_con .tab_box .el-tabs--card > .el-tabs__header .el-tabs__item{
    border-left: 1px solid rgba(var(--themecolor),0.55);
}
.college_detail_box .college_detail_con .tab_box .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
    color: #666;
    background-color: #fff;
}
.college_detail_box .college_detail_con .tab_box .el-tabs--card>.el-tabs__header .el-tabs__item.is-active.is-focus {
  box-shadow: none;
  border-radius: 0;
}
.college_left_nav .left_nav_box .el-scrollbar__wrap{
    overflow-x: hidden;
}
.detail_layer_back .detail_layer_box .layer_con .el-scrollbar__wrap{
    overflow-x: hidden;
}
</style>